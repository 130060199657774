<template>
  <v-navigation-drawer
    right
    v-model="drawer"
    fixed temporary
    :width="$vuetify.breakpoint.xs ? 350 : 500"
  >
    <!-- HEADER -->
    <template v-slot:prepend>
      <v-toolbar color="color2 color2Text--text" tile dense class="top">
        <v-toolbar-title>{{['add', 'confirmAdd'].includes(window) ? 'New Member' : selected.full}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          color="color1 color1Text--text"
          fab
          small
          @click.stop="drawer=false"
        >
          <v-icon small>fas fa-times</v-icon>
        </v-btn>
      </v-toolbar>
    </template>
    <!-- FOOTER -->
    <template v-slot:append>
      <v-divider></v-divider>
      <v-card-actions>
        <v-row dense>
          <v-col cols="6">
            <v-btn
              :color="activeBtn.color"
              :disabled="activeBtn.disabled"
              :loading="activeBtn.loading"
              @click.stop="activeBtn.click"
              block
              class="py-9"
            >
              {{ activeBtn.text }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              color="error white--text"
              @click.stop="activeBtn.cancel"
              :disabled="loading"
              block
              class="py-9"
            >
            {{activeBtn.cancelText || 'Cancel'}}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </template>
    <!-- BODY -->
    <v-card-text>
      <v-container>
        <v-overlay v-if="loading" color="white">
          <v-progress-circular
            indeterminate
            size="64"
            color="color3"
          ></v-progress-circular>
        </v-overlay>
        <v-row dense>
          <v-col cols="12">
            <v-window v-model="window" touchless>
              <!-- DETAILS WINDOW-->
              <v-window-item value="contact">
                <v-card v-if="selectedAdmin" flat>
                  <v-list>
                    <v-list-item style="min-height: 72px">
                      <v-list-item-action>
                        <v-icon color="color2">far fa-shield-alt</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-select
                          :items="staffTypes"
                          v-model="eRole"
                          label="Role"
                          v-if="edit"
                          color="color3"
                          item-color="color3"
                          hide-details
                        ></v-select>
                        <v-list-item-title v-else>
                          {{selected.role}}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <template v-if="emailSettings.length">
                      <v-list v-for="(email) in emailSettings" :key="email.id">
                        <v-list-item>
                          <v-list-item-action>
                            <v-icon color="color2">fas fa-envelope</v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>{{email.address}}</v-list-item-title>
                            <v-list-item-subtitle class="caption">{{email.id}}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list dense>
                          <v-list-item v-for="s in email.settings" :key="`${s.id}-${s.setting}`">
                            <v-list-item-action>

                            </v-list-item-action>
                            <v-list-item-content>
                              <v-switch
                                :label="s.text"
                                v-model="s.on"
                                hide-details
                                class="mt-0 ml-2"
                                color="success"
                                :disabled="!edit"
                              ></v-switch>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-list>
                    </template>
                  </v-list>
                  <div class="xsmall grey--text text-right">
                    Added: {{selectedAdmin.dtCreated | unixToLocalDt}} By: {{selectedAdmin.userCreated || 'System'}}<br>
                    Last Edited: {{selectedAdmin.dtModified | unixToLocalDt}} By: {{selectedAdmin.userModified || 'System'}}<br>
                  </div>
                  <v-divider v-if="!noEdit"></v-divider>
                  <v-card-actions v-if="!noEdit">
                    <v-fab-transition>
                      <v-btn
                        color="error"
                        text
                        @click.stop="window = 'confirm'"
                        class="xsmall"
                        v-if="edit && !noEdit">Remove</v-btn>
                    </v-fab-transition>
                    <v-fab-transition>
                      <v-btn
                        color="color3"
                        text
                        @click.stop="window = 'resend'"
                        class="xsmall"
                        v-if="edit && selectedAdmin.isInvite">Resend</v-btn>
                    </v-fab-transition>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-window-item>
              <!-- DELETE CONFIRM WINDOW-->
              <v-window-item value="confirm">
                <v-card v-if="selectedAdmin" flat>
                  <v-card-title class="title">
                    Are you sure?
                  </v-card-title>
                  <v-card-text>
                    If you remove {{selected.full}}, they will not have access to any of your organization or tournament settings.
                  </v-card-text>
                </v-card>
              </v-window-item>
              <!-- ADD NEW WINDOW-->
              <v-window-item value="add">
                <v-card flat>
                  <v-container>
                    <v-row dense>
                      <v-col cols="12">
                        <v-text-field
                          label="Email"
                          hint="Enter the email address of the person you would like to add"
                          v-model="emailSearch"
                          type="search"
                          autocomplete="false"
                          persistent-hint
                          :rules="$v.emailSearch.$dirty ? [
                            () => $v.emailSearch.email || 'A valid email is required'
                          ] : []"
                          @input="$v.emailSearch.$touch();"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-alert type="error" :value="!!this.addError" transition="scale-transition">
                          {{this.addError}}
                        </v-alert>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-window-item>
              <!-- CONFIRM ADD WINDOW-->
              <v-window-item value="confirmAdd">
                <v-card flat>
                  <v-container>
                    <v-row dense v-if="searchResults.length > 1">
                      <v-col cols="12">
                        We found {{searchResults.length}} users with a confirmed email address of: <b>{{emailSearch}}</b>.<br>
                        Please select the user you would like to add
                      </v-col>
                      <v-select
                        :items="searchResults"
                        v-model="newAdmin"
                        label="Users"
                        :item-text="(item) => `${item.firstName} ${item.lastName} (${item.userName})`"
                        return-object
                        color="color3"
                        item-color="color3"
                      ></v-select>
                    </v-row>
                    <v-expand-transition>
                      <div v-if="newAdmin">
                        <v-alert type="error"
                          v-if="!!addError"
                          :value="true"
                          transition="scale-transition"
                          :key="newAdmin.id"
                        >
                          {{addError}}
                        </v-alert>
                        <v-row dense v-else>
                          <v-col cols="12" class="title" v-if="newAdmin.id">
                            Add {{newAdmin.firstName}} {{newAdmin.lastName}} as
                          </v-col>
                          <template v-if="searchResults.length === 0">
                            <v-col cols="12">
                              We were unable to find a user with a confirmed email address of: <b>{{emailSearch}}</b>.<br>
                              Enter his or her name and we will send them an invite.
                              Once he or she confirms their email address, they will be added to your orginazation.
                            </v-col>
                            <v-col cols="12" sm="6">
                              <v-text-field
                                label="First Name*"
                                v-model="newAdmin.firstName"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                              <v-text-field
                                label="Last Name*"
                                v-model="newAdmin.lastName"
                              ></v-text-field>
                            </v-col>
                          </template>
                          <v-col cols="12">
                            <v-select
                              :items="staffTypes"
                              v-model="eRole"
                              label="Role*"
                              color="color3"
                              item-color="color3"
                            ></v-select>
                          </v-col>
                        </v-row>
                      </div>
                    </v-expand-transition>
                  </v-container>
                </v-card>
              </v-window-item>
              <!-- RESEND INVITE WINDOW-->
              <v-window-item value="resend">
                <v-card flat>
                  <v-card-text class="title text-center">
                    Resend invite email?
                  </v-card-text>
                </v-card>
              </v-window-item>
            </v-window>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-navigation-drawer>
</template>

<script>
import { firstBy } from 'thenby'
import { validationMixin } from 'vuelidate'
import { email } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import { mapOrganizationSetting } from '@/classes/NotificationSettings.js'
import flatten from '../../helpers/ArrayFlatten'

export default {
  props: ['username', 'affiliateId', 'selectedAdmin', 'adminData'],
  mixins: [validationMixin],
  validations () {
    return {
      emailSearch: { email }
    }
  },
  data () {
    return {
      loading: false,
      drawer: false,
      window: 'contact',
      edit: false,
      eRole: 'Admin',
      add: false,
      newAdmin: null,
      emailSearch: null,
      searching: false,
      searchResults: [],
      emailSettings: []
    }
  },
  computed: {
    ...mapGetters(['liveUpdates', 'user', 'getPageInfo']),
    organization () {
      return this.getPageInfo(this.username)
    },
    owners () {
      return this.adminData && this.adminData.filter(f => f.role === 'Owner' && f.isActive).sort(firstBy('isInvite').thenBy(t => t.user.lastName))
    },
    hasOwner () {
      return this.owners && !!this.owners.find(f => !f.isInvite)
    },
    noEdit () {
      return this.hasOwner && this.affiliateId
    },
    admins () {
      return this.adminData && this.adminData.filter(f => f.role === 'Admin' && f.isActive).sort(firstBy('isInvite').thenBy(t => t.user.lastName))
    },
    refs () {
      return this.adminData && this.adminData.filter(f => f.role.startsWith('Score') && f.isActive).sort(firstBy('isInvite').thenBy(t => t.user.lastName))
    },
    base () {
      return {
        first: '',
        last: '',
        full: '',
        role: '',
        emails: [],
        phones: []
      }
    },
    selected () {
      if (!this.selectedAdmin) {
        return this.base
      }
      const details = {
        first: this.selectedAdmin.user.firstName,
        last: this.selectedAdmin.user.lastName,
        full: `${this.selectedAdmin.user.firstName} ${this.selectedAdmin.user.lastName}`,
        role: this.selectedAdmin.role,
        emails: this.selectedAdmin.user.emails,
        phones: this.selectedAdmin.user.phones
      }

      return details
    },
    activeBtn () {
      if (this.window === 'contact') {
        return this.edit ? {
          color: 'success white--text',
          icon: 'fas fa-save',
          text: 'Save',
          disabled: this.eRole === this.selected.role && this.dirtySettings.length === 0,
          loading: this.loading,
          click: this.saveEdit,
          cancel: () => { this.edit = false }
        } : {
          color: 'color3 color3Text--text',
          icon: 'fas fa-pen',
          text: 'Edit',
          disabled: this.loading || this.noEdit,
          click: this.editMe,
          cancel: () => { this.drawer = false }
        }
      }

      return this.window === 'confirm' ? {
        color: 'success white--text',
        icon: 'fas fa-save',
        text: 'Remove',
        loading: this.loading,
        click: this.remove,
        cancel: () => { this.window = 'contact' }
      } : this.window === 'add' ? {
        color: 'success white--text',
        icon: 'fas fa-save',
        text: 'Search',
        disabled: (!this.$v.$dirty || this.$v.$invalid),
        loading: this.searching,
        click: this.userSearch,
        cancel: () => { this.drawer = false }
      } : this.window === 'confirmAdd' ? {
        color: 'success white--text',
        icon: 'fas fa-save',
        text: 'Confirm',
        disabled: (!this.newAdmin || !this.newAdmin.firstName || !this.newAdmin.lastName || !this.eRole || !!this.addError),
        loading: this.loading,
        click: this.addMe,
        cancel: () => { this.window = 'add' }
      } : this.window === 'resend' ? {
        color: 'success white--text',
        icon: 'fas fa-save',
        text: 'Yes',
        loading: this.loading,
        click: this.doResend,
        cancel: () => { this.window = 'contact' },
        cancelText: 'No'
      } : {
        color: 'color3Text color3--text',
        icon: 'fas fa-pen',
        text: 'Edit',
        click: this.editMe,
        disabled: this.loading
      }
    },
    addError () {
      if (!this.newAdmin) return false
      if (!this.newAdmin.emails.includes(this.emailSearch)) return false
      const x = this.adminData.find(f => f.userId === this.newAdmin.id)
      return x ? `${this.newAdmin.firstName} ${this.newAdmin.lastName} is already a member.` : false
    },
    orgId () {
      return this.affiliateId || this.organization.id
    },
    staffTypes () {
      const result = ['Owner', 'Admin', 'Score Keeper']
      if (this.organization.props.includes('CD')) result.push('Coach')

      return result
    },
    dirtySettings () {
      return flatten(this.emailSettings.map(m => m.settings)).filter(f => f.dirty)
    }
  },
  methods: {
    open () {
      this.drawer = true
    },
    remove () {
      const dto = {
        organizationId: this.orgId,
        userId: this.selectedAdmin.userId,
        isActive: false,
        isInvite: this.selectedAdmin.isInvite
      }
      this.loading = true
      this.$VBL.post.admins(this.username, dto)
        .then(r => {
          this.selectedAdmin.isActive = false
          this.$emit('member-change')
        })
        .catch(e => console.log(e.response))
        .finally(() => {
          this.loading = false
          this.drawer = false
        })
    },
    doResend () {
      this.loading = true
      this.$VBL.post.adminInvite(this.username, this.selectedAdmin.userId)
        .then(r => {
          this.drawer = false
        })
        .catch(e => console.log(e.response))
        .finally(() => {
          this.loading = false
          this.drawer = false
        })
    },
    onCloseDialog () {
      this.window = 'contact'
      this.add = false
      this.emailSearch = null
      this.searchResults = []
      this.newAdmin = null
      this.emailSettings = []
      this.$v.$reset()
      this.cancelEdit()
      this.$emit('closed')
    },
    addMe () {
      if (!this.newAdmin) return
      let dto = null
      if (this.newAdmin.id) {
        dto = {
          organizationId: this.orgId,
          userId: this.newAdmin.id,
          role: this.eRole,
          isActive: true
        }
      } else {
        dto = {
          organizationId: this.orgId,
          role: this.eRole,
          user: {
            firstName: this.newAdmin.firstName,
            lastName: this.newAdmin.lastName,
            emails: [this.emailSearch]
          },
          isInvite: true
        }
      }
      this.loading = true
      this.$VBL.post.admins(this.username, dto)
        .then(r => {
          this.adminData.push(r.data)
          this.$emit('member-change')
        })
        .catch(e => console.log(e.response))
        .finally(() => {
          this.loading = false
          this.drawer = false
        })
    },
    editMe () {
      this.eRole = this.selected.role
      this.edit = true
    },
    cancelEdit () {
      this.eRole = this.selected ? this.selected.role : 'Admin'
      this.edit = false
    },
    saveEdit () {
      // if (this.eRole === this.selectedAdmin.role) return
      const dto = {
        organizationId: this.orgId,
        userId: this.selectedAdmin.userId,
        role: this.eRole,
        isActive: this.selectedAdmin.isActive,
        isInvite: this.selectedAdmin.isInvite,
        notificationSettings: this.dirtySettings
      }
      this.loading = true
      this.$VBL.post.admins(this.username, dto)
        .then(r => { this.selectedAdmin.role = this.eRole })
        .catch(e => console.log(e.response))
        .finally(() => {
          this.loading = false
          this.drawer = false
        })
    },
    userSearch () {
      this.searchResults = []
      this.newAdmin = JSON.parse(JSON.stringify(this.base))
      this.searching = true
      this.$VBL.user.search(this.emailSearch, null, true)
        .then(r => {
          this.processSearch(r.data)
        })
        .catch(e => console.log(e))
        .finally(() => {
          this.searching = false
        })
    },
    processSearch (results) {
      this.searchResults = results
      if (results.length === 1) this.newAdmin = results[0]
      if (!this.addError) this.window = 'confirmAdd'
    },
    addMember () {
      this.newAdmin = JSON.parse(JSON.stringify(this.base))
      if (this.affiliateId) this.eRole = 'Owner'
      this.window = 'add'
      this.drawer = true
    },
    getNotificationSettings () {
      if (!this.selectedAdmin || this.selectedAdmin.id === 0 || this.selectedAdmin.isInvite) return
      this.loading = true
      this.$http.get(`organization/${this.username}/admins/${this.selectedAdmin.userId}/notifications`)
        .then(r => {
          this.emailSettings = r.data
          this.emailSettings.forEach(f => {
            f.settings = mapOrganizationSetting(this.organization.id, f.id, f.settings)
          })
        })
        .catch(e => console.log(e.response))
        .finally(() => { this.loading = false })
    }
  },
  watch: {
    drawer: function (val) {
      if (!val) {
        this.onCloseDialog()
      } else {
        this.getNotificationSettings()
      }
    }
  }
}
</script>

<style>

</style>
