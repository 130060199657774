class NotificationSettingDto {
  id = 0
  setting = null
  text = null
  userEmailId = null
  deleted = true
  deletedOG = true

  get on () {
    return !this.deleted
  }

  set on (v) {
    this.deleted = !v
  }

  get dirty () {
    return this.deleted !== this.deletedOG
  }
}

const organizationBaseOptions = [
  { suffix: 'TournamentRegistration', text: 'Event Registrations' },
  // { suffix: 'TournamentWithdraw', text: 'Event Withdraw' },
  { suffix: 'Refund', text: 'Event Withdraws/Refunds Issued' },
  { suffix: 'ChangeRequest', text: 'Change Requests' },
  { suffix: 'RosterChange', text: 'Player Changes' },
  { suffix: 'ProductSale', text: 'Product Sales' }
]

const mapOrganizationSetting = (organizationId, userEmailId, existing) => {
  return organizationBaseOptions.map(m => {
    const s = `organization-${organizationId}-${m.suffix}`
    const x = existing.find(f => f.setting === s)
    const result = new NotificationSettingDto()
    result.setting = s
    result.text = m.text
    result.userEmailId = userEmailId
    if (x) {
      result.id = x.id
      result.deletedOG = x.deleted
      result.deleted = x.deleted
    }
    return result
  })
}

export { mapOrganizationSetting }
